<template>
  <v-app>
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div
            class="
              col-md-12 col-lg-12 col-sm-12
              mb-2
              d-flex
              justify-content-end
              px-4
              pt-2
              mb-0
              pb-1
            "
          >
            <button
              class="btn btn-outline-danger btn-circle text-center"
              v-b-tooltip.hover.bottomright="'Reset foto'"
              @click="resetImg"
            >
              <i class="fas fa-times pl-1"></i>
            </button>
          </div>
          <div class="col-md-12 col-lg-12 col-sm-12 mb-2">
            <div
              class="
                d-flex
                justify-content-center
                align-items-center
                image-container
              "
            >
              <div style="height: 350px; width: 350px; position: relative">
                <!-- upload Preview -->
                <img
                  class="image"
                  :src="[
                    uploadedImageUrl == '' ? form.photo_name : uploadedImageUrl,
                  ]"
                  @load="onImageLoad()"
                  v-if="uploadMethod == 'upload'"
                />

                <!-- camera -->
                <div v-if="uploadMethod == 'camera'" class="mb-3">
                  <vue-web-cam
                    ref="webcam"
                    :device-id="deviceId"
                    width="100%"
                    @started="onStarted"
                    @stopped="onStopped"
                    @error="onError"
                    @cameras="onCameras"
                    @camera-change="onCameraChange"
                    v-if="captureStatus == false"
                  />

                  <figure class="figure" v-else-if="captureStatus == true">
                    <img
                      :src="baseImg"
                      class="img-responsive"
                      style="height: 350px; width: 350px"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 col-sm-12 mb-2">
            <div class="row">
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  v-if="uploadMethod == 'camera' && captureStatus == false"
                  @click="onCapture"
                >
                  <i class="fas fa-camera"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-secondary w-100"
                  v-if="uploadMethod == 'upload' || captureStatus == true"
                >
                  <i class="fas fa-camera"></i>
                </button>
              </div>
              <div class="col-md-6">
                <b-button
                  text="Button"
                  variant="success"
                  @click="changeUploadBtn"
                  v-if="uploadMethod == 'camera'"
                  class="w-100"
                  ><i class="fas fa-eye"></i
                ></b-button>
                <b-button
                  text="Button"
                  variant="danger"
                  @click="changeUploadBtn"
                  v-if="uploadMethod == 'upload'"
                  class="w-100"
                  ><i class="fas fa-eye-slash"></i
                ></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md">
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <!-- Input Title -->
          <b-form-group>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="Tn"
                name="customRadio"
                class="custom-control-input"
                value="Tn"
                v-model="form.title"
                @change="setGender"
              />
              <label class="custom-control-label" for="Tn">Tuan(Tn)</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="Ny"
                name="customRadio"
                class="custom-control-input"
                value="Ny"
                v-model="form.title"
                @change="setGender"
              />
              <label class="custom-control-label" for="Ny">Nyonya(Ny)</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="Nn"
                name="customRadio"
                class="custom-control-input"
                value="Nn"
                v-model="form.title"
                @change="setGender"
              />
              <label class="custom-control-label" for="Nn">Nona(Nn)</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="An"
                name="customRadio"
                class="custom-control-input"
                value="An"
                v-model="form.title"
              />
              <label class="custom-control-label" for="An">Anak(An)</label>
            </div>
            <small class="d-block text-danger">{{ error.title }}</small>
          </b-form-group>

          <!-- Input patient id -->
          <b-form-group id="input-group-patien-id">
            <label for="input-patien-id" class="d-block" v-if="edit == false"
              >ID Pasien: <em class="text-muted">opsional</em></label
            >
            <label for="input-patien-id" class="d-block" v-if="edit == true"
              >ID Pasien:</label
            >
            <b-form-input
              id="input-patien-id"
              v-model="form.patient_id"
              placeholder="Id Pasien"
              @keyup="checkPatientId(form.patient_id)"
            >
            </b-form-input>
            <small class="text-muted" v-if="edit == false"
              >*Jika Kosong, Sistem Akan Menggenerate Otomatis ID</small
            >
            <small class="text-danger">{{ error.patient_id }}</small>
          </b-form-group>

          <!-- Input Name -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama Lengkap"
            >
            </b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <!-- Input Ktp Number -->
          <b-form-group id="input-group-ktp">
            <label for="input-ktp" class="d-block"
              >Nomor KTP: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-ktp"
              v-model="form.id_card_number"
              placeholder="Nomor Ktp"
            >
            </b-form-input>
            <small class="text-danger">{{ error.id_card_number }}</small>
          </b-form-group>

          <!-- Input Date of Birth -->
          <b-form-group
            id="input-group-birth-date"
            label="Tempat & Tanggal Lahir:"
            label-for="input-sip-birth-date"
          >
            <b-input-group>
              <div class="row">
                <div class="col-md-6">
                  <b-form-input
                    id="input-birth-place"
                    aria-label="Tempat Lahir"
                    placeholder="Tempat Lahir (opsional)"
                    v-model="form.birt_place"
                  ></b-form-input>
                </div>
                <div class="col-md-6">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="form.date"
                    persistent
                    width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-input-group>
                        <b-input-group-prepend>
                          <button
                            class="btn btn-secondary"
                            type="button"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fas fa-calendar"></i>
                          </button>
                        </b-input-group-prepend>
                          <b-form-input
                            id="input-trigger-modal-patient"
                            v-model="form.birt_date"
                            placeholder="Tanggal Lahir (YYYY-MM-DD)"
                            @input="formaterDate"
                          >
                          </b-form-input>
                      </b-input-group>
                    </template>

                    <v-date-picker
                      v-if="modal2"
                      v-model="form.birt_date"
                      locale="id"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.birt_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>

            </b-input-group>
            <div class="row">
              <div class="col">
                <small class="text-danger">{{ error.birt_place }}</small>
              </div>
              <div class="col">
                <small class="text-danger">{{ error.birt_date }}</small>
              </div>
            </div>
          </b-form-group>

          <b-form-group>
            <label class="d-block"
              >Status: <em class="text-muted">opsional</em></label
            >

            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="merried"
                name="status"
                class="custom-control-input"
                value="Menikah"
                v-model="form.status"
              />
              <label class="custom-control-label" for="merried">Menikah</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="notMerried"
                name="status"
                class="custom-control-input"
                value="Belum Menikah"
                v-model="form.status"
              />
              <label class="custom-control-label" for="notMerried"
                >Belum Menikah</label
              >
            </div>
            <small class="d-block text-danger">{{ error.status }}</small>
          </b-form-group>

          <b-form-group label="Jenis Kelamin:">
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="male"
                name="gender"
                class="custom-control-input"
                value="Laki-laki"
                v-model="form.gender"
              />
              <label class="custom-control-label" for="male">Laki-laki</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="female"
                name="gender"
                class="custom-control-input"
                value="Perempuan"
                v-model="form.gender"
              />
              <label class="custom-control-label" for="female">Perempuan</label>
            </div>
            <small class="d-block text-danger">{{ error.gender }}</small>
          </b-form-group>

          <b-form-group>
            <label for="input-email" class="d-block"
              >Golongan Darah: <em class="text-muted">opsional</em></label
            >
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="A-"
                name="bloodType"
                class="custom-control-input"
                value="A-"
                v-model="form.blood_type"
              />
              <label class="custom-control-label" for="A-">A-</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="A+"
                name="bloodType"
                class="custom-control-input"
                value="A+"
                v-model="form.blood_type"
              />
              <label class="custom-control-label" for="A+">A+</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="B-"
                name="bloodType"
                class="custom-control-input"
                value="B-"
                v-model="form.blood_type"
              />
              <label class="custom-control-label" for="B-">B-</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="B+"
                name="bloodType"
                class="custom-control-input"
                value="B+"
                v-model="form.blood_type"
              />
              <label class="custom-control-label" for="B+">B+</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="O-"
                name="bloodType"
                class="custom-control-input"
                value="O-"
                v-model="form.blood_type"
              />
              <label class="custom-control-label" for="O-">O-</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="O+"
                name="bloodType"
                class="custom-control-input"
                value="O+"
                v-model="form.blood_type"
              />
              <label class="custom-control-label" for="O+">O+</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="AB-"
                name="bloodType"
                class="custom-control-input"
                value="AB-"
                v-model="form.blood_type"
              />
              <label class="custom-control-label" for="AB-">AB-</label>
            </div>
            <div class="custom-control custom-radio d-inline m-2">
              <input
                type="radio"
                id="AB+"
                name="bloodType"
                class="custom-control-input"
                value="AB+"
                v-model="form.blood_type"
              />
              <label class="custom-control-label" for="AB+">AB+</label>
            </div>

            <small class="text-danger">{{ error.blood_type }}</small>
          </b-form-group>

          <!-- Input Alergic Type -->
          <b-form-group id="input-group-alergic">
            <label for="input-alergic"
              >Jenis Alergi: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-alergic"
              v-model="form.allergy"
              placeholder="Alergi"
            >
            </b-form-input>
            <small class="text-danger">{{ error.allergy }}</small>
          </b-form-group>

          <!-- Input Parent Name -->
          <b-form-group id="input-group-parent-name">
            <label for="input-parent-name"
              >Nama Orang Tua: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-parent-name"
              v-model="form.parent_name"
              placeholder="Nama Orang Tua"
            ></b-form-input>
            <small class="text-danger">{{ error.parent_name }}</small>
          </b-form-group>

          <!-- Input Mobile Phone -->
          <b-form-group
            id="input-group-mobile-phone"
            label="Nomor Telepon Seluler: "
            label-for="input-mobile-phone"
          >
            <b-form-input
              id="input-mobile-phone"
              v-model="form.mobile_phone"
              placeholder="Telepon Seluler"
            ></b-form-input>
            <small class="text-danger">{{ error.mobile_phone }}</small>
          </b-form-group>

          <!-- Input Phone -->
          <b-form-group id="input-group-phone">
            <label for="input-phone"
              >Telepon : <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-phone"
              v-model="form.phone"
              placeholder="Telepon"
            ></b-form-input>
            <small class="text-danger">{{ error.phone }}</small>
          </b-form-group>

          <!-- Input Email -->
          <b-form-group id="input-group-email">
            <label for="input-email"
              >Email : <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-email"
              v-model="form.email"
              type="email"
              placeholder="Email"
            ></b-form-input>
            <small class="text-danger">{{ error.email }}</small>
          </b-form-group>

          <!-- Input Address -->
          <b-form-group id="input-group-address">
            <label for="input-address"
              >Alamat: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-address"
              v-model="form.address"
              rows="3"
              placeholder="Alamat Lengkap"
            ></b-form-textarea>
            <small class="text-danger">{{ error.address }}</small>
          </b-form-group>

          <!-- <div class="row">
            <div class="col-md-12 mb-2">
              <span class="font-weight-bold">Alamat Tinggal :</span>
            </div>
          </div> -->

          <!-- Input Province -->
          <!-- <b-form-group id="input-group-province">
            <label for="input-address"
              >Provinsi: </label
            >
            <treeselect
              v-model="form.province_id"
              :options="region"
              placeholder="Pilih Provinsi"
              @input="regionSet"
              required
            />
            <small class="text-danger">{{ error.province }}</small>
          </b-form-group> -->

          <!-- Input Capital -->
          <!-- <b-form-group id="input-group-capital" v-if="isRegion">
            <label for="input-address"
              >Kota/kabupaten: </label
            >
            <treeselect
              v-model="form.capital_id"
              :options="regionChild"
              placeholder="Pilih Kota/Kabupaten"
              required
            />
            <small class="text-danger">{{ error.capital }}</small>
          </b-form-group> -->

          <!-- Input Doctor -->
          <b-form-group id="input-group-doctor">
            <label for="input-doctor"
              >Dokter Yang Menangani:
              <em class="text-muted">opsional</em></label
            >
            <treeselect
              v-model="form.doctor_in_charge"
              :multiple="true"
              :options="doctors"
              placeholder="Pilih Dokter"
            />
            <small class="text-danger">{{ error.doctor_in_charge }}</small>
          </b-form-group>

          <!-- Input Responsible Doctor -->
          <b-form-group id="input-group-doctor">
            <label for="input-doctor"
              >Dokter Penanggung Jawab (DPJP):
              <em class="text-muted">opsional</em></label
            >
            <treeselect
              v-model="form.responsible_doctor"
              :multiple="true"
              :options="doctors"
              placeholder="Pilih Dokter"
            />
          </b-form-group>

          <!-- Input Description -->
          <b-form-group id="input-group-description">
            <label for="input-description"
              >Catatan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.notes"
              rows="3"
              placeholder="Catatan"
            ></b-form-textarea>
            <small class="text-danger">{{ error.notes }}</small>
          </b-form-group>

          <!-- Profile Image -->
          <div class="row">
            <div class="col-md-12">
              <b-form-group id="input-group-image">
                <label for="input-email"
                  >Foto Profil: <em class="text-muted">opsional</em></label
                >
                <b-form-file
                  v-model="form.photo"
                  placeholder="Upload Gambar"
                  drop-placeholder="Drop file here..."
                  @change="onFileUpload"
                ></b-form-file>
              </b-form-group>
            </div>
          </div>

          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            class="ml-2"
            variant="default"
            @click="$router.push('/patient/list')"
          >
            Cancel
          </b-button>
        </b-form>
      </div>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import webCam from 'vue-web-cam'
import { WebCam } from "vue-web-cam";

export default {
  name: "Form",

  components: {
    Treeselect,
    "vue-web-cam": WebCam,
  },

  props: {
    form: Object,
    route: String,
    purpose: String,
    password: Boolean,
    edit: Boolean,
  },

  data() {
    return {
      error: {
        email: "",
        name: "",
        birt_place: "",
        birt_date: "",
        address: "",
        gender: "",
        phone: "",
        mobile_phone: "",
        parent_name: "",
        blood_type: "",
        id_card_number: "",
        password: "",
        password_confirmation: "",
        allergy: "",
        title: "",
        status: "",
        doctor_in_charge: "",
        photo: [],
        patient_id: "",
        notes: "",
        region: "",
        capital: "",
      },
      doctors: [],
      users: null,
      imgLoaded: false,
      uploadedImageUrl: "",
      uploadMethod: "upload",
      // camera
      baseImg: null,
      camera: null,
      deviceId: null,
      devices: [],
      img: null,
      captureStatus: false,

      // date
      time: null,
      menu2: false,
      modal2: false,
      picker: new Date().toISOString().substr(0, 10),

      // other
      textPatientId: "0",
    };
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.birt_date = this.form.birt_date + "-";
      }
      if (val.length == 7) {
        this.form.birt_date = this.form.birt_date + "-";
      }
    },

    setGender() {
      if (this.form.title == "Tn") {
        this.form.gender = "Laki-laki";
      } else if (this.form.title != "Tn" && this.form.title != "An") {
        this.form.gender = "Perempuan";
      }
    },

    regionSet(data) {
      let status = this.getRegionChildOptions(data);

      if (status) {
        this.isRegion = true;
      }
    },

    async getRegionOptions() {
      let response = await module.setTreeSelect("regions");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.region = response.data;
      }

      if (this.form.province_id != null) {
        this.isRegion = true;
        this.getRegionChildOptions(this.form.province_id);
      }
    },

    async getRegionChildOptions(id) {
      let response = await module.setTreeSelect("region/" + id);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.regionChild = response.data;
      }

      return true;
    },

    resetImg() {
      this.form.isResetImg = true;
      this.form.photo_name = s3Endpoint + this.form.photo_default;
      this.uploadedImageUrl = s3Endpoint + this.form.photo_default;
    },

    async checkPatientId(number) {
      if (number == "") {
        number = 0;
      }

      let response = await module.get("patients-number/" + number);

      console.log(response, "resss");
      if (response == 1) {
        this.textPatientId = "1";
      } else {
        this.textPatientId = "0";
      }
    },

    onImageLoad() {
      this.imgLoaded = true;
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.photo = evt.target.files[0];
      this.form.isResetImg = false;
    },

    oncancel() {
      if (this.purpose == "master") {
        // this.onStop()
        this.$router.push("/patient/list");
      } else if (this.purpose == "modal") {
        // this.onStop()
        $bvModal.hide("modal-patient");
      }
    },

    changeUploadBtn() {
      if (this.uploadMethod == "upload") {
        this.uploadMethod = "camera";
        setTimeout(() => {
          this.onStart();
        }, 4000);
      } else {
        this.uploadMethod = "upload";
      }
    },

    async getDoctorOptions() {
      let response = await module.setTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
      }
    },

    async getUser() {
      let response = await module.setSelectOption("users");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.users = response.data;
        this.users.unshift({ text: "Pilih User", value: "", disabled: true });
      }
    },

    async formOnSubmit() {
      if (this.textPatientId == "0") {
        if (this.img != null) {
          this.form.photo = this.img;
        }

        if (this.form.gender == "Laki-laki") {
          this.form.gender = "male";
        } else {
          this.form.gender = "female";
        }

        this.form.doctor_in_charge = JSON.stringify(this.form.doctor_in_charge);
        this.form.responsible_doctor = JSON.stringify(
          this.form.responsible_doctor
        );
        let formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          // if (key == 'doctor_in_charge' || key == 'responsible_doctor') {
          //   value = JSON.stringify(value)
          // }
          formData.append(key, value);
        }
        // Make Request
        let response = await module.submit(formData, this.route);
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.form.doctor_in_charge = JSON.parse(this.form.doctor_in_charge);
          this.form.responsible_doctor = JSON.parse(
            this.form.responsible_doctor
          );
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          this.$root.$emit("UpdateNewPatient");
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          if (this.purpose == "master") {
            this.$router.push("/patient/list");
          } else if (this.purpose == "modal") {
            this.$emit("submitPatient", {
              id: response.success.item.original.id,
              name:
                response.success.item.original.name +
                ` (${response.success.item.original.phone})`,
            });
          }
        }
      } else {
        Swal.fire(
          "Perhatian",
          "Nomor pasien telah digunakan, silahkan masukkan nomor lain",
          "warning"
        );
      }
    },

    // camera method
    onCapture() {
      this.baseImg = this.$refs.webcam.capture();
      this.img = this.dataURItoBlob(this.baseImg);
      this.captureStatus = true;
      this.onStop();
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onPause() {
      this.$refs.webcam.pause();
    },
    onResume() {
      this.$refs.webcam.resume();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },

    // convert base64 to image
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString,
      });
    },
  },

  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },

  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },

  mounted() {
    this.getUser();
    this.getDoctorOptions();
    // this.getRegionOptions();
  },
};
</script>

<style>
/* style button */
.btn-circle {
  width: 30px;
  height: 30px;
  border-radius: 35px;
  padding: 0;
}

.btn-circle-sm {
  width: 18px;
  height: 18px;
  border-radius: 30px;
  padding: 0;
}

.btn-circle i {
  font-size: 17px;
}
</style>